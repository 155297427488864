import Layout from "../components/index/layout"
import SEO from "../components/seo"
import React, {useContext, useEffect, useRef, useState} from "react"
import CustomLink from "../components/link"
import {Link} from "gatsby"

import slider1 from "../../static/images/slider/travel1.jpg"
import slider2 from "../../static/images/slider/travel2.jpg"
import slider3 from "../../static/images/slider/travel3.jpg"
import slider4 from "../../static/images/slider/travel4.jpg"
import acercade from "../../static/video/acerca-de.mp4"
import integral from "../../static/video/integral.mp4"
import reconoce from "../../static/video/reconoce.mp4"
import apoyamos from "../../static/video/apoyamos.mp4"
import especializamos from "../../static/video/especializamos.mp4"
import LangContext from "../context/LangContext";

const jQuery = require("jquery");
const IndexPage = (props) => {
    const {lang} = useContext(LangContext)
    const [data, setData] = useState({})
    if (lang === "es") {
        import ("../messages/es")
            .then((msg) => {
                setData(msg.default)
            })
    } else {
        import ("../messages/en")
            .then((msg) => {
                setData(msg.default)
            })
    }
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            if (typeof window !== "undefined") {
                let jquery_funtions =window.funciones
            }
        }
        return () => {
            isMountedComponent.current = false
        }
    })

    return (
        <Layout location={props.location}>
            <SEO title="LatAm Capital Advisors"/>
            <>
                <section className="p-0 overlap-height">
                    <div id="rev_slider_1083_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container"
                         data-alias="travel" data-source="gallery" style={{
                        margin: '0px auto',
                        backgroundColor: 'transparent',
                        padding: '0px',
                        marginTop: '0px',
                        marginBottom: '0px'
                    }}>
                        {/* START REVOLUTION SLIDER 5.4.1 auto mode */}
                        <div id="rev_slider_1083_1" className="rev_slider fullwidthabanner" style={{display: 'none'}}
                             data-version="5.4.1">
                            <ul>    {/* SLIDE  */}
                                <li data-index="rs-3070" data-transition="fade" data-slotamount="default"
                                    data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default"
                                    data-easeout="default" data-masterspeed="default"
                                    data-thumb="" data-rotate={0}
                                    data-fstransition="fade" data-fsmasterspeed={1500} data-fsslotamount={7}
                                    data-saveperformance="off" data-title="" data-param1 data-param2
                                    data-param3
                                    data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10
                                    data-description>
                                    {/* MAIN IMAGE */}
                                    <img src={slider1} alt="" data-bgposition="center center"
                                         data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax={10}
                                         className="rev-slidebg" data-no-retina/>
                                    {/* LAYERS */}
                                </li>
                                {/* SLIDE  */}
                                <li data-index="rs-3071" data-transition="fade" data-slotamount="default"
                                    data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default"
                                    data-easeout="default" data-masterspeed="default"
                                    data-thumb="" data-rotate={0}
                                    data-saveperformance="off" data-title="" data-param1 data-param2
                                    data-param3
                                    data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10
                                    data-description>
                                    {/* MAIN IMAGE */}
                                    <img src={slider2} alt="" data-bgposition="center center"
                                         data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax={10}
                                         className="rev-slidebg" data-no-retina/>
                                    {/* LAYERS */}
                                </li>
                                {/* SLIDE  */}
                                <li data-index="rs-3072" data-transition="fade" data-slotamount="default"
                                    data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default"
                                    data-easeout="default" data-masterspeed="default"
                                    data-thumb="" data-rotate={0}
                                    data-saveperformance="off" data-title="" data-param1 data-param2
                                    data-param3
                                    data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10
                                    data-description>
                                    {/* MAIN IMAGE */}
                                    <img src={slider3} alt="" data-bgposition="center center"
                                         data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax={10}
                                         className="rev-slidebg" data-no-retina/>
                                    {/* LAYERS */}
                                </li>
                                {/* SLIDE  */}
                                <li data-index="rs-3073" data-transition="fade" data-slotamount="default"
                                    data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default"
                                    data-easeout="default" data-masterspeed="default"
                                    data-thumb="" data-rotate={0}
                                    data-saveperformance="off" data-title="" data-param1 data-param2
                                    data-param3
                                    data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10
                                    data-description>
                                    {/* MAIN IMAGE */}
                                    <img src={slider4} alt="" data-bgposition="center center"
                                         data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax={10}
                                         className="rev-slidebg" data-no-retina/>
                                    {/* LAYERS */}
                                </li>
                            </ul>
                            <div style={{}} className="tp-static-layers">
                                {/* LAYER NR. 1 */}
                                <div data-wow-delay=".3s"
                                     className="tp-caption Travel-BigCaption tp-resizeme tp-static-layer wow animate__fadeInUp"
                                     id="slider-1083-layer-1"
                                     data-x="['left','left','left','left']"
                                     data-hoffset="['90','90','60','40']"
                                     data-y="['center','center','center','center']"
                                     data-voffset="['185','160','120','70']"
                                     data-fontsize="['55','35','30','25']"
                                     data-lineheight="['50','50','50','35']" data-width="none" data-height="none"
                                     data-whitespace="" data-type="text" data-responsive_offset="on"
                                     data-startslide={0} data-endslide={3}
                                     data-frames="[{&quot;delay&quot;:0,&quot;speed&quot;:300,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;y:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;power3.inOut&quot;}]"
                                     data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                     data-paddingleft="[0,0,0,0]"
                                     style={{zIndex: 5, textTransform: 'left'}}> Leading global financial expertise
                                </div>
                                {/* LAYER NR. 2 */}
                                <div data-wow-delay=".3s"
                                     className="tp-caption Travel-SmallCaption tp-resizeme tp-static-layer wow animate__fadeInUp"
                                     id="slider-1083-layer-2"
                                     data-x="['left','left','left','left']"
                                     data-hoffset="['90','90','60','40']"
                                     data-y="['center','center','center','center']"
                                     data-voffset="['280','240','200','160']"
                                     data-fontsize="['30','25','20','15']"
                                     data-lineheight="['30','30','30','20']" data-width="100%" data-height="none"
                                     data-whitespace="" data-type="text" data-responsive_offset="on"
                                     data-startslide={0} data-endslide={3}
                                     data-frames="[{&quot;from&quot;:&quot;opacity:0;&quot;,&quot;speed&quot;:300,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:500,&quot;ease&quot;:&quot;Power2.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;nothing&quot;}]"
                                     data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                     data-paddingleft="[0,0,0,0]"
                                     style={{zIndex: 6, textTransform: 'left'}}>

                                    Extensive experience as financial advisor, structuring agent and fund manager
                                    for <br className="d-none d-sm-block"/>
                                    infrastructure, energy and real estate projects
                                    in Latin America

                                </div>
                                {/*LAYER NR. 3*/}
                                <div data-wow-delay=".3s"
                                     className="tp-caption Travel-CallToAction rev-btn  tp-static-layer wow animate__fadeInLeft"
                                     id="slider-1083-layer-3"
                                     data-x="['left','left','left','left']"
                                     data-hoffset="['75','75','45','25']"
                                     data-y="['center','center','center','center']"
                                     data-voffset="['230','195','155','115']"
                                     data-actions="[{&quot;event&quot;:&quot;click&quot;,&quot;action&quot;:&quot;scrollbelow&quot;,&quot;offset&quot;:&quot;px&quot;,&quot;delay&quot;:&quot;&quot;}]"
                                     data-responsive_offset="on" data-responsive="off" data-startslide={0}
                                     data-endslide={3}
                                     data-frames="[{&quot;from&quot;:&quot;opacity:0;&quot;,&quot;speed&quot;:300,&quot;to&quot;:&quot;o:1;&quot;,&quot;delay&quot;:500,&quot;ease&quot;:&quot;Power2.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;nothing&quot;},{&quot;frame&quot;:&quot;hover&quot;,&quot;speed&quot;:&quot;300&quot;,&quot;ease&quot;:&quot;Power1.easeInOut&quot;,&quot;to&quot;:&quot;o:1;rX:0;rY:0;rZ:0;z:0;&quot;,&quot;style&quot;:&quot;c:rgba(255, 255, 255, 1.00);bg:rgba(255, 255, 255, 0.15);bw:2px 2px 2px 2px;&quot;}]"
                                     data-textalign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                                     data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                     data-paddingleft="[0,0,0,0]" style={{
                                    zIndex: 7,
                                    width: '50px',
                                    whiteSpace: 'nowrap',
                                    textTransform: 'left',
                                    outline: 'none',
                                    boxShadow: 'none',
                                }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* start section */}
                <section className="home-consulting cover-background">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-lg-5 md-margin-5-rem-bottom wow animate__fadeIn">
                                <h4 className="sub-hairline alt-font font-weight-500 text-extra-dark-gray letter-spacing-minus-1px margin-1-rem-bottom">
                                    <span
                                        className="text-tussock font-weight-600">Latam Capital <br/> Advisors - LCA</span>
                                </h4>
                                <p className="w-90 margin-40px-bottom lg-w-90 md-margin-25px-bottom">
                                    We specialize in infrastructure, energy, and real estate projects in Latin America,
                                    providing a wide range of financial services including advisory for debt and capital
                                    raising, designing innovative financial structures, mergers &
                                    acquisitions (M&A) advisory, debt restructurings, and fund management.
                                </p>

                                <CustomLink to="nosotros#transacciones">
                                    <div className="d-flex align-items-center arrow">
                                        <div
                                            className="btn btn-fancy btn-small btn-dark-gray xs-margin-10px-bottom">OUR
                                            SUCCESSFUL TRANSACTIONS
                                        </div>
                                        <span className="btn-arrow"></span>
                                    </div>
                                </CustomLink>
                            </div>
                            <div className="col-12 col-lg-7 wow animate__fadeIn"
                                 data-wow-delay="0.2s">
                                <video autoPlay="autoplay" loop="loop" muted="muted" playsInline="playsInline"
                                       src={acercade}
                                       data-autoplay>
                                    <track kind="captions" srcLang="es" src={''}/>
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end section */}
                {/* start section */}
                <section id="experiencia">
                    <div className="container">
                        <div
                            className="row row-cols-1 row-cols-md-2 row-cols-sm-2 row-cols-lg-4 justify-content-center">
                            {/* start text box item */}
                            <div
                                className="col sm-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeInUp integral">
                                <div
                                    className="feature-box text-center padding-1-rem-all lg-padding-1-rem-lr sm-padding-2-rem-lr">
                                    <div className="feature-box-icon">
                                        <h2 className="font-weight-300 letter-spacing-minus-2px">~
                                            150 years</h2>
                                    </div>
                                    <div className="feature-box-content last-paragraph-no-margin">
                                        <span
                                            className="alt-font font-weight-500 margin-5px-bottom d-block text-extra-dark-gray">of combined <br/> experience</span>
                                        <p>of the partners at LCA who have held leadership positions
                                            in investment banking, development banks, and infrastructure
                                            financings
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* end text box item */}
                            {/* start text box item */}
                            <div
                                className="col sm-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeInUp integral"
                                data-wow-delay="0.2s">
                                <div
                                    className="feature-box text-center padding-1-rem-all lg-padding-1-rem-lr sm-padding-2-rem-lr">
                                    <div className="feature-box-icon">
                                        <h2 className="font-weight-300 letter-spacing-minus-2px">~
                                            {data['numero_proyectos']} projects</h2>
                                    </div>
                                    <div className="feature-box-content last-paragraph-no-margin">
                                        <span
                                            className="alt-font font-weight-500 margin-5px-bottom d-block text-extra-dark-gray">successfully <br/>completed </span>
                                        <p>and proven track record, building a unique project portfolio and
                                            institutional network in the sector and in the region
                                        </p>

                                    </div>
                                </div>
                            </div>
                            {/* end text box item */}
                            {/* start text box item */}
                            <div className="col wow animate__fadeInUp integral" data-wow-delay="0.4s">
                                <div
                                    className="feature-box text-center padding-1-rem-all lg-padding-1-rem-lr sm-padding-2-rem-lr">
                                    <div className="feature-box-icon">
                                        <h2 className="font-weight-300 letter-spacing-minus-2px">~
                                            {data['numero_recursos']} billion dollars</h2>
                                    </div>
                                    <div className="feature-box-content last-paragraph-no-margin">
                                        <span
                                            className="alt-font font-weight-500 margin-5px-bottom d-block text-extra-dark-gray">raised in successful transactions</span>
                                        <p>and innovative senior debt issuances, mezzanine financing, loans,
                                            restructurings, and mergers & acquisitions
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* end text box item */}
                            {/* start text box item */}
                            <div className="col wow animate__fadeInUp integral" data-wow-delay="0.4s">
                                <div
                                    className="feature-box text-center padding-1-rem-all lg-padding-1-rem-lr sm-padding-2-rem-lr">
                                    <div className="feature-box-icon">
                                        <h2 className="font-weight-300 letter-spacing-minus-2px">~
                                            12 years</h2>
                                    </div>
                                    <div className="feature-box-content last-paragraph-no-margin">
                                        <span
                                            className="alt-font font-weight-500 margin-5px-bottom d-block text-extra-dark-gray">of LCA’s operation
<br/> in Mexico</span>
                                        <p>originating, designing and monitoring infrastructure projects</p>
                                    </div>
                                </div>
                            </div>
                            {/* end text box item */}
                        </div>

                        {/*<div className="row justify-content-center align-items-center">*/}
                        {/*<div className="col-12 text-center">*/}
                        {/*<CustomLink to="nosotros#equipo"*/}
                        {/*className="btn btn-fancy btn-small btn-dark-gray  xs-margin-10px-bottom">Conoce*/}
                        {/*nuestro equipo</CustomLink>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </section>
                {/* end section */}
                <section className="home-consulting cover-background">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-lg-6 wow animate__fadeIn"
                                 data-wow-delay="0.2s">
                                <video autoPlay="autoplay" loop="loop" muted="muted" playsInline="playsInline"
                                       src={integral}
                                       data-autoplay>
                                    <track kind="captions" srcLang="es" src={''}/>
                                </video>
                            </div>
                            <div
                                className="col-12 col-lg-5 offset-lg-1 md-margin-5-rem-bottom wow animate__fadeIn">
                                <h4 className="sub-hairline alt-font font-weight-500 text-extra-dark-gray letter-spacing-minus-1px margin-1-rem-bottom margin-15px-top w-80 lg-w-90 xs-w-100">
                                    A Uniquely Capable Team
                                </h4>
                                <p className="w-90 margin-20px-bottom lg-w-90 md-margin-25px-bottom">
                                    Each partner at LCA brings unique expertise in domestic and international
                                    investment banking, rooted in decades of experience in the industry.
                                </p>
                                <p className="w-90 margin-40px-bottom lg-w-90 md-margin-25px-bottom">
                                    We strive to cultivate long-term relationships, by advising and supporting our
                                    clients from the early stages of each project, while providing innovative and
                                    customized solutions.
                                </p>

                                <div className="row">
                                    <div className="col-12">
                                        <CustomLink to="nosotros#equipo">
                                            <div className="d-flex align-items-center arrow">
                                                <div
                                                    className="btn btn-fancy btn-small btn-dark-gray xs-margin-10px-bottom">MEET
                                                    OUR TEAM
                                                </div>
                                                <span className="btn-arrow"></span>
                                            </div>
                                        </CustomLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-consulting">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-lg-5 md-margin-5-rem-bottom wow animate__fadeIn">
                                <h4 className="sub-hairline alt-font font-weight-500 text-extra-dark-gray letter-spacing-minus-1px margin-1-rem-bottom">
                                    LCA is recognized for:</h4>

                                <ul className="list-style-02">
                                    <li className="margin-15px-bottom"><i
                                        className="fas fa-plus text-small text-tussock position-relative top-2px margin-10px-right"
                                        aria-hidden="true"/><span>Expert insight backed by decades of industry experience.
</span>
                                    </li>
                                    <li className="margin-15px-bottom"><i
                                        className="fas fa-plus text-small text-tussock position-relative top-2px margin-10px-right"
                                        aria-hidden="true"/><span>In-depth knowledge of the global financial sector, focused on Latin America.
</span>
                                    </li>
                                    <li className="margin-15px-bottom"><i
                                        className="fas fa-plus text-small text-tussock position-relative top-2px margin-10px-right"
                                        aria-hidden="true"/><span>Long-term relationships and client commitment built on innovative and tailor-made solutions.
</span>
                                    </li>
                                    <li className="margin-15px-bottom"><i
                                        className="fas fa-plus text-small text-tussock position-relative top-2px margin-10px-right"
                                        aria-hidden="true"/><span>Providing advisory services from the projects´ early stages, offering a close relationship, highest quality services, and consistently delivering outstanding results.
</span>
                                    </li>
                                    <li className="margin-15px-bottom"><i
                                        className="fas fa-plus text-small text-tussock position-relative top-2px margin-10px-right"
                                        aria-hidden="true"/><span>Strong core values, including highest ethical standards and professional responsibility, always seeking alignment with the best interests of its clients and their shareholders.
</span>
                                    </li>
                                </ul>
                                <div className="margin-40px-top d-inline-block md-margin-25px-top">
                                    <CustomLink to="nosotros#reconocimientos">
                                        <div className="d-flex align-items-center arrow">
                                            <div
                                                className="btn btn-fancy btn-small btn-dark-gray xs-margin-10px-bottom">AWARDS
                                                RECEIVED
                                            </div>
                                            <span className="btn-arrow"></span>
                                        </div>
                                    </CustomLink>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 offset-lg-1 wow animate__fadeIn"
                                 data-wow-delay="0.2s">
                                <video autoPlay="autoplay" loop="loop" muted="muted" playsInline="playsInline"
                                       src={reconoce}
                                       data-autoplay>
                                    <track kind="captions" srcLang="es" src={''}/>
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-consulting cover-background potencial">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-lg-6 wow animate__fadeIn"
                                 data-wow-delay="0.2s">
                                <video autoPlay="autoplay" loop="loop" muted="muted" playsInline="playsInline"
                                       src={apoyamos}
                                       data-autoplay>
                                    <track kind="captions" srcLang="es" src={''}/>
                                </video>
                            </div>
                            <div
                                className="col-12 col-lg-5 offset-lg-1 md-margin-5-rem-bottom wow animate__fadeIn">
                                <h4 className="sub-hairline alt-font font-weight-500 text-extra-dark-gray letter-spacing-minus-1px margin-2-rem-bottom">We
                                    support first-class projects and companies to help them reach their full potential
                                </h4>
                                <div
                                    className="mt-5 panel-group accordion-event accordion-style-03 lg-margin-2-rem-bottom"
                                    id="accordion2" data-active-icon="fa-angle-down"
                                    data-inactive-icon="fa-angle-right">
                                    {/* start accordion item */}
                                    <div
                                        className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeInUp"
                                        data-wow-delay="0.4s">
                                        <div className="panel-heading">
                                            <CustomLink className="accordion-toggle collapsed"
                                                        to="servicios#tab-servicios?activate=asesoria">
                                                <div className="panel-title">
                                                    <span
                                                        className="alt-font text-extra-dark-gray d-inline-block font-weight-500">Financial advisory and structuring Agent</span>
                                                    <i className="indicator fas fa-angle-right text-tussock icon-extra-small"/>
                                                </div>
                                            </CustomLink>
                                        </div>
                                    </div>
                                    {/* end accordion item */}
                                    {/* start accordion item */}
                                    <div
                                        className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeInUp"
                                        data-wow-delay="0.6s">
                                        <div className="panel-heading">
                                            <CustomLink className="accordion-toggle collapsed"
                                                        to="servicios#tab-servicios?activate=valuacion">
                                                <div className="panel-title">
                                                    <span
                                                        className="alt-font text-extra-dark-gray d-inline-block font-weight-500">Valuation and independent analysis</span>
                                                    <i className="indicator fas fa-angle-right text-tussock icon-extra-small"/>
                                                </div>
                                            </CustomLink>
                                        </div>
                                    </div>
                                    {/* end accordion item */}
                                    {/* start accordion item */}
                                    <div
                                        className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeInUp"
                                        data-wow-delay="0.6s">
                                        <div className="panel-heading">
                                            <CustomLink className="accordion-toggle collapsed"
                                                        to="servicios#tab-servicios?activate=situaciones">
                                                <div className="panel-title">
                                                    <span
                                                        className="alt-font text-extra-dark-gray d-inline-block font-weight-500">Restructuring and special situations</span>
                                                    <i className="indicator fas fa-angle-right text-tussock icon-extra-small"/>
                                                </div>
                                            </CustomLink>
                                        </div>
                                    </div>
                                    {/* end accordion item */}
                                    {/* start accordion item */}
                                    <div
                                        className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeInUp mb-0"
                                        data-wow-delay="0.6s">
                                        <div className="panel-heading">
                                            <CustomLink className="accordion-toggle collapsed"
                                                        to="servicios#tab-servicios?activate=gestion-tab">
                                                <div className="panel-title">
                                                    <span
                                                        className="alt-font text-extra-dark-gray d-inline-block font-weight-500">Fund management</span>
                                                    <i className="indicator fas fa-angle-right text-tussock icon-extra-small"/>
                                                </div>
                                            </CustomLink>
                                        </div>
                                    </div>
                                    {/* end accordion item */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-consulting cover-background potencial">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-lg-5 md-margin-5-rem-bottom wow animate__fadeIn">
                                <h4 className="sub-hairline alt-font font-weight-500 text-extra-dark-gray letter-spacing-minus-1px margin-2-rem-bottom">
                                    We specialize in strategic sectors in Latin America</h4>
                                <div
                                    className="mt-5 panel-group accordion-event accordion-style-03 margin-4-rem-bottom lg-margin-2-rem-bottom"
                                    id="accordion2" data-active-icon="fa-angle-down"
                                    data-inactive-icon="fa-angle-right">
                                    {/* start accordion item */}
                                    <div
                                        className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeInUp"
                                        data-wow-delay="0.4s">
                                        <div className="panel-heading">
                                            <CustomLink className="accordion-toggle collapsed"
                                                        to="sectores#tab-sectores?activate=transportacion">
                                                <div className="panel-title">
                                                    <span
                                                        className="alt-font text-extra-dark-gray d-inline-block font-weight-500">Transportation</span>
                                                    <i className="indicator fas fa-angle-right text-tussock icon-extra-small"/>
                                                </div>
                                            </CustomLink>
                                        </div>
                                    </div>
                                    {/* end accordion item */}
                                    {/* start accordion item */}
                                    <div
                                        className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeInUp"
                                        data-wow-delay="0.4s">
                                        <div className="panel-heading">
                                            <CustomLink className="accordion-toggle collapsed"
                                                        to="sectores#tab-sectores?activate=energia">
                                                <div className="panel-title">
                                                    <span
                                                        className="alt-font text-extra-dark-gray d-inline-block font-weight-500">Energy</span>
                                                    <i className="indicator fas fa-angle-right text-tussock icon-extra-small"/>
                                                </div>
                                            </CustomLink>
                                        </div>
                                    </div>
                                    {/* end accordion item */}
                                    {/* start accordion item */}
                                    <div
                                        className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeInUp"
                                        data-wow-delay="0.6s">
                                        <div className="panel-heading">
                                            <CustomLink className="accordion-toggle collapsed"
                                                        to="sectores#tab-sectores?activate=telecomunicaciones">
                                                <div className="panel-title">
                                                    <span
                                                        className="alt-font text-extra-dark-gray d-inline-block font-weight-500">Telecommunications</span>
                                                    <i className="indicator fas fa-angle-right text-tussock icon-extra-small"/>
                                                </div>
                                            </CustomLink>
                                        </div>
                                    </div>
                                    {/* end accordion item */}
                                    {/* start accordion item */}
                                    <div
                                        className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeInUp"
                                        data-wow-delay="0.6s">
                                        <div className="panel-heading">
                                            <CustomLink className="accordion-toggle collapsed"
                                                        to="sectores#tab-sectores?activate=infraestructura">
                                                <div className="panel-title">
                                                    <span
                                                        className="alt-font text-extra-dark-gray d-inline-block font-weight-500">Social infrastructure</span>
                                                    <i className="indicator fas fa-angle-right text-tussock icon-extra-small"/>
                                                </div>
                                            </CustomLink>
                                        </div>
                                    </div>
                                    {/* end accordion item */}
                                    {/* start accordion item */}
                                    <div
                                        className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeInUp"
                                        data-wow-delay="0.6s">
                                        <div className="panel-heading">
                                            <CustomLink className="accordion-toggle collapsed"
                                                        to="sectores#tab-sectores?activate=finanzas">
                                                <div className="panel-title">
                                                    <span
                                                        className="alt-font text-extra-dark-gray d-inline-block font-weight-500">Public finance</span>
                                                    <i className="indicator fas fa-angle-right text-tussock icon-extra-small"/>
                                                </div>
                                            </CustomLink>
                                        </div>
                                    </div>
                                    {/* end accordion item */}
                                    {/* start accordion item */}
                                    <div
                                        className="panel bg-white box-shadow-small border-radius-5px wow animate__fadeInUp"
                                        data-wow-delay="0.6s">
                                        <div className="panel-heading">
                                            <CustomLink className="accordion-toggle collapsed"
                                                        to="sectores#tab-sectores?activate=sector">
                                                <div className="panel-title">
                                                    <span
                                                        className="alt-font text-extra-dark-gray d-inline-block font-weight-500">Real estate</span>
                                                    <i className="indicator fas fa-angle-right text-tussock icon-extra-small"/>
                                                </div>
                                            </CustomLink>
                                        </div>
                                    </div>
                                    {/* end accordion item */}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 offset-lg-1 wow animate__fadeIn"
                                 data-wow-delay="0.2s">
                                <video autoPlay="autoplay" loop="loop" muted="muted" playsInline="playsInline"
                                       src={especializamos}
                                       data-autoplay>
                                    <track kind="captions" srcLang="es" src={''}/>
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Layout>
    )
}

export default IndexPage
